<template>
  <booking />
</template>

<script>
  import Booking from './form'
  export default {
    components: { Booking },
  }
</script>
